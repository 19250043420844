import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CrudService } from 'src/app/services/crud.service';
import { ActivityService } from 'src/app/services/activity.service';
import gql from 'graphql-tag';
declare const $: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  user: string;
  userEmail: any;
  token: string;
  postForm: any;
  submitted: boolean;
  error: string;
  spinner:boolean;

  get f() {
    return this.postForm.controls;
  }

  constructor(private route: ActivatedRoute, private scrollTopService:ScrollService,private toastr: ToastrService, private formBuilder: FormBuilder, private activity: ActivityService, private router: Router, private crud: CrudService) { 
    this.postForm = this.formBuilder.group({
      password: ["", [Validators.required]],
      confirmpassword: ["", [Validators.required]],
    });
    
  }

  ngOnInit() {
    this.scrollTopService.setScrollTop();

    this.route.paramMap.subscribe( (param:any) => {
      this.token = param.params.data;
            
    })
    $(".toggle-password").click(function () {

      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }

  gotoLogin(){
    this.router.navigate(['/student-portal'])
  }

  resetPassword() {
    this.submitted = true;
    if (this.postForm.invalid) {
      this.toastr.error("Invalid Password", "Error")
      return;
    }else if (this.postForm.value.password != this.postForm.value.confirmpassword) {
      this.toastr.error("Your passwords are not the same", "Error")
      return
    }

    let obj={
      password: this.postForm.value.password,
      token: this.token
    }

    this.spinner= true;
    let query = gql`
    mutation changePassword(
      $token: String!
      $password: String!){
        changePassword(token: $token, password: $password) {
          id
        }
        }
      `;
    this.crud.postData(query, obj).subscribe(result => {

      if (!result.data['changePassword']) {
        this.error = result.errors[0].message;
        this.spinner= false;
        return
      }
      this.spinner= false;
      this.toastr.success("Password Changed Successfully, Login to continue", "Success")
      this.router.navigate(['/login'])

    })

  }
}
