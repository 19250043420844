import { Component, OnInit } from '@angular/core';
import gql from 'graphql-tag';
import { CrudService } from 'src/app/services/crud.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { CompileShallowModuleMetadata } from '@angular/compiler';
declare const $: any;

@Component({
  selector: 'app-dispute',
  templateUrl: './dispute.component.html',
  styleUrls: ['./dispute.component.css']
})
export class DisputeComponent implements OnInit {
  userProfile: any;
  disputes: { name: string; }[];
  allDisputes: any;

  constructor(public crud: CrudService, private toastr: ToastrService) {
    this.disputes = [
      { name: 'Payout' },
      { name: 'Top up' },
      { name: 'Ticket Purchase' },
      { name: 'Other' },

    ]
  }

  ngOnInit() {
    this.getUserObj()
    this.pullAllDispute()
  }
  getUserObj() {
    this.userProfile = JSON.parse(localStorage.getItem('user'))
  }

  createDispute(data: NgForm) {
    let bankObj = {
      userId: this.userProfile.id,
      type: data.value.dispute_type,
      content: data.value.dispute_description,
      device: 'web'
    }
    let query = gql`
    mutation createDispute($disputeInput: disputeInputType!) {
      createDispute(disputeInput: $disputeInput) {
        id
      }
      }
    `;

    this.crud.postData(query, { disputeInput: bankObj }).subscribe(result => {
      this.toastr.success('Dispute Successfully Created', 'Success!');
      this.pullAllDispute()
      data.reset()
    },
      error => {
        this.toastr.error(error.errors.message, 'Error');
      })
  }

  pullAllDispute() {
    let data = gql`
      query userDisputes($userId:ID!){
        userDisputes(userId: $userId) {
          createdAt
          type
          content
          ref
          status
        }
      }
    `
    this.crud.pullData(data, { userId: this.userProfile.id, played: "1" })
      .subscribe(result => {
        this.allDisputes = result.data["userDisputes"];
        

        setInterval(() => {
          $('.shortlistedApplicant').DataTable();
        }, 2000);
      },
        error => {
          this.toastr.error("Oops, we could not get you ticket's data, please check your network and try again", "Unsuccessful")
        });
  }
}
