import { CrudService } from 'src/app/services/crud.service';
import { Component, OnInit } from '@angular/core';
import gql from 'graphql-tag';
import { log } from 'console';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.css']
})
export class AgentsComponent implements OnInit {
  agents: any = [];
  
  states = [
    "Abia State",
    "Adamawa State",
    "Akwa State Ibom",
    "Anambra State",
    "Bauchi State",
    "Bayelsa State",
    "Benue State",
    "Borno State",
    "Cross State River",
    "Delta State",
    "Ebonyi State",
    "Edo State",
    "Ekiti State",
    "Enugu State",
    "Gombe State",
    "Imo State",
    "Jigawa State",
    "Kaduna State",
    "Kano State",
    "Katsina State",
    "Kebbi State",
    "Kogi State",
    "Kwara State",
    "Lagos State",
    "Nasarawa State",
    "Niger State",
    "Ogun State",
    "Ondo State",
    "Osun State",
    "Oyo State",
    "Plateau State",
    "Rivers State",
    "Sokoto State",
    "Taraba State",
    "Yobe State",
    "Zamfara State",
    "FCT",
]
  rets: any;

  constructor(private crudService: CrudService) { }

  ngOnInit() {
    this.pullAgents()
  }
  pullAgents() {

		let data = gql`
 query agents {
			agents {
		
					id
					name
					phone					
						state
					address

			}
      }
    `
		this.crudService.pullData(data, {})
			.subscribe(result => {
				this.rets = result.data["agents"];
        
        
        
        
        for (let index = 0; index < this.states.length; index++) {
          const element = this.states[index];
          let ret = this.getRet(element);
          if(ret.length > 0)  this.agents.push({state: element, rets: ret})
          
        }
        
        
			},
				error => {
					// this.toastr.error("Oops, we could not get you agent's data, please check your network and try again", "Unsuccessful")
				});
	}
  getRet(state){
    let rets =  this.rets.filter(res =>{
      return res.state == state
    })
    
    
    
    return rets
  }
}
