
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import gql from 'graphql-tag';
import { CrudService } from '../services/crud.service';
import { Toast, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
  token: any;
  atoken: any;

  constructor(private route: ActivatedRoute, private router: Router, private crud: CrudService, private toastr: ToastrService) {
    this.route.paramMap.subscribe((param: any) => {
      this.token = param.params.data;
      this.atoken = param.params.adata;
      if (this.token != null) {
        this.verifyEmail();
      }
      if (this.atoken != null) {
        this.verifyAgentEmail();
      }
    })
  }

  ngOnInit() {
  }
  verifyEmail() {
    
    
    
    let query = gql`
      mutation verifyEmail($token:String!) {
        verifyEmail(token:$token) {
          email
        }
        }
      `;

    this.crud.postData(query, { token: this.token })
      .subscribe((result) => {
        // this.walletBalance = result.data['addtoWallet'];
        this.toastr.success("Your email has been verified", "Success")
        this.router.navigate(['/dashboard'])

        // this.getTransactionHistory(this.userProfile.id);
        // this.subject.wallet.next(this.walletBalance);
        // this.modalRef.hide();
      })
  }
  verifyAgentEmail() {

    let query = gql`
      mutation verifyAgentEmail($token:String!) {
        verifyAgentEmail(token:$token) {
          email
        }
        }
      `;

    this.crud.postData(query, { token: this.atoken })
      .subscribe((result) => {
        // this.walletBalance = result.data['addtoWallet'];
        this.toastr.success("Your email has been verified", "Success")
        this.router.navigate(['/'])

        // this.getTransactionHistory(this.userProfile.id);
        // this.subject.wallet.next(this.walletBalance);
        // this.modalRef.hide();
      })
  }
}
