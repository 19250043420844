import { Component, OnInit } from '@angular/core';
import { CrudService } from '../services/crud.service';
import gql from 'graphql-tag';
import { FormBuilder, Validators } from '@angular/forms';
declare const $: any;

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {
  postForm: any;
  results: any;
  submitted: boolean;
  loginForm = true;
  singleTicket: {
    createdAt: any; ticketId: any; amount: any; games: any; playStatus: any; totalWinning: any; draw: {
      // ticketDraw: data.draw.combination.split(""),
      drawDate: any;
    };
  };
  setting: any;

  constructor(private crudService:CrudService, private formBuilder:FormBuilder) {
    this.postForm = this.formBuilder.group({
      entry: ["", [Validators.required]],
    });
   }
   get f() {
    return this.postForm.controls;
  }

  ngOnInit() {
    this.getSetting()
  }
  pullTickets() {
    
    
    
    this.submitted = true;

    if (this.postForm.invalid) {

      return;
    }
    let data = gql`
      query checkResult($entry:String!){
        checkResult(entry: $entry) {
          createdAt
          amount
          games
          playStatus
          ticketId
          totalWinning
          draw{
            combination
            drawDate
            drawType{
              name
            }
          }
        }
      }
    `
    this.crudService.pullData(data, { entry: this.postForm.value.entry })
      .subscribe(result => {
        this.results = result.data["checkResult"];
        setInterval(() => {
          $('.disputeList').DataTable();
        }, 2000);
      },
        error => {
          // this.toastr.error("Oops, we could not get you ticket's data, please check your network and try again", "Unsuccessful")
        });
  }
  ticketDetails(data) {

    let gameArrToDisplay = this.ticketWins(data);
    this.singleTicket = {
      createdAt: data.createdAt,
      ticketId: data.ticketId,
      amount: data.amount,
      games: gameArrToDisplay,
      playStatus: data.playStatus,
      totalWinning: data.totalWinning,
      draw: {
        // ticketDraw: data.draw.combination.split(""),
        drawDate: data.draw.drawDate,
        // drawType: {
        //   name: data.draw.drawType.name
        // }
      }
    }
    if (data.draw.combination) this.singleTicket.draw['ticketDraw'] = data.draw.combination.split("");
    $('#exampleModalCenter').modal('show')
  }
  ticketWins(data) {
    let gameArr = JSON.parse(data.games);
    let combinationArr = []
    let gameArrToDisplay = [];
    let draw = [];
    let gameDraw;
    if (data.draw.combination != null) {
      gameDraw = data.draw.combination.split("");

      gameDraw.forEach(element => {
        draw.push({ letter: element, status: "unused" })
      });
    }

    gameArr.forEach(element => {
      combinationArr.push(element.combination.split(""))
    });

    for (let i = 0; i < combinationArr.length; i++) {
      let combObjt = []
      combinationArr[i].forEach(element => {
        combObjt.push({ status: "pending", letter: element })
      });;
      gameArrToDisplay.push(combObjt)
    }
    for (let index = 0; index < gameArrToDisplay.length; index++) {
      draw.forEach(element => {
        element.status = "unused";
      });

      draw.forEach(element => {
        gameArrToDisplay[index].forEach(innerElement => {
          if (element.letter == innerElement.letter && element.status == "unused" && innerElement.status == "pending") {
            innerElement["status"] = "win";
            element.status = "used"
          }
        });
      });

    }

    return gameArrToDisplay
  }
  getSetting() {
    let data = gql`
		  {
			getSetting {
			 flashMessage
       liveLink
       liveStatus
       ticketDetails
			}
		  }
		`
    this.crudService.pullData(data, {})
      .subscribe(result => {
        this.setting = result.data["getSetting"];

        setInterval(() => {
          // $('.withdrawalTable').DataTable();
        }, 2000);
      },
        error => {
          // this.toastr.error("Oops, we could not get you user's data, please check your network and try again", "Unsuccessful")
        });
  }
}