import { CrudService } from './../../services/crud.service';
import { Component, OnInit } from '@angular/core';
import gql from 'graphql-tag';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css']
})
export class NoticeComponent implements OnInit {
  notices: any;

  constructor(private crud: CrudService) { }

  ngOnInit() {
    this.getNotices();
  }
  getNotices() {
    let data = gql`
		  {
			notices {
				id
			 title
       content
			 createdAt
			}
		  }
		`
    this.crud.pullData(data, {})
      .subscribe(result => {
        this.notices = result.data["notices"];
        setInterval(() => {
          // $('.withdrawalTable').DataTable();
        }, 2000);
      },
        error => {
          // this.toastr.error("Oops, we could not get you user's data, please check your network and try again", "Unsuccessful")
        });
  }
}
