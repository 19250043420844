import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import gql from 'graphql-tag';
import { Toast, ToastrService } from 'ngx-toastr';
import { CrudService } from '../../services/crud.service';
import { SubjectService } from '../../services/subject.service';
declare const $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  user: any;
  menu = [
    {
      title: "Tickets",
      url: "/dashboard/home",
      // icon: faTachometerAlt
      icon: faGamepad

    },
    {
      title: "Wallet",
      url: "/dashboard/my-wallet",
      icon: faWallet
    },
    {
      title: "Dispute",
      url: "/dashboard/dispute",
      icon: faQuestionCircle
    },
    // {
    //   title: "Transaction",
    //   url: "/dashboard/transactions",
    //   icon: faQuestionCircle
    // },
  ]
  faHome = faHome;
  faTimesCircle = faTimesCircle;
  faInfo = faInfo;
  constructor(private router: Router, private subject: SubjectService, private crud: CrudService, private toastr: ToastrService) { }
  userObj: any;
  ngOnInit() {
    $(document).ready(function () {
      $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
      });
    });
    $(document).ready(function () {
      $('#sidebarCollapse2').on('click', function () {
        $('#sidebar').toggleClass('active');
      });
    });

    this.openHelp();

    this.user = JSON.parse(localStorage.getItem("user"));
    this.getUserDetails(this.user.id);
    this.subject.wallet.subscribe((data) => {
      // 

      this.user.wallet_balance = data['wallet_balance'];
    });
  }

  toggleActive(){
    $('#sidebar').toggleClass('active');
  }
  
  getUserDetails(id) {
    let data = gql`
    query user($id: ID!){
      user (id: $id){
        wallet_balance
        account_number
        account_name
        bank_name
        email
        name
        uuid
        status
      }
    }
  `;
    this.crud.pullData(data, { id: id })
      .subscribe(result => {
        this.user = result.data['user']
        // 
      },
        error => {
          this.toastr.error("Oops, we cannot fetch your data at this time, kindly try again", "Unsuccessful")
        });
  }
  sendEmailVerification() {

    let query = gql`
      mutation sendEmailVerification($email:String!) {
        sendEmailVerification(email:$email) {
          email
        }
        }
      `;

    this.crud.postData(query, { email: this.user.email })
      .subscribe((result) => {
        // this.walletBalance = result.data['addtoWallet'];
        this.toastr.success("A verification email has been sent to your email", "Success")

        // this.getTransactionHistory(this.userProfile.id);
        // this.subject.wallet.next(this.walletBalance);
        // this.modalRef.hide();
      })
  }
  logout() {
    this.user = null;
    localStorage.removeItem('user');
    localStorage.removeItem('token');

    this.router.navigate(['/login'])
  }

  openHelp() {
    $('#helpModal').modal('show')
  }
}
