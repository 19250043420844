import { faGamepad, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
declare const $: any;
import Swal from 'sweetalert2'
import { CrudService } from '../../services/crud.service';
import gql from 'graphql-tag';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare const PaystackPop: any;

function removeSpaces(control) {
  const trimmedValue = control.value.replace(/\s/g, '');
  return trimmedValue === control.value ? null : { hasSpaces: true };
}
@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.css']
})
export class BuyComponent implements OnInit {
  ticketForm: any;
  userSelectedNumber: any = [];
  submitted: boolean;
  userTicket: any[] = [];
  user_ticket_submission_array: any[] = [];
  lotteryAmountTotal: number;
  ticketCounter: number;
  faTimesCircle = faTimesCircle
  setting: any;
  banks: any;
  resolvedBankInfo: any = {};
  constructor(private formBuilder: FormBuilder, private http: HttpClient, private crud: CrudService, private toastr: ToastrService, private api: CrudService) { }

  ngOnInit() {
    this.ticketForm = this.formBuilder.group({
      // title: ['', Validators.required],
      // firstName: ['', Validators.required],
      // lastName: ['', Validators.required],
      firstname: ['', [Validators.required]],
      lastname: ['', ],
      account_number: [''],
      bank_name: [],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required]],
      // confirmPassword: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue]
    });
    this.getSetting()
    var top = this;
    $(".number-list li").on('click', function () {
      if (document.querySelectorAll(".number-list li.active").length < 6) {
        $(this).toggleClass("active");
        if ($(this).hasClass("active")) {
          top.userSelectedNumber.push($(this).html())
        } else {
          top.userSelectedNumber.splice(top.userSelectedNumber.indexOf($(this).html()), 1)
        }
        return
      } else if (($(this).hasClass("active"))) {
        $(this).removeClass("active");
        top.userSelectedNumber.splice(top.userSelectedNumber.indexOf($(this).html()), 1)
      } else {
        $(this).removeClass("active");
        if (document.querySelectorAll(".number-list li.active").length == 6) {
          alert("You have reached the maximum number of Raffle Alphabets Allowed")
        }
      }
    });
    // this.pullTickets();
    this.makeGetRequest()
  }
  handleTicketSubmission() {
    Swal.fire({
      title: 'Buy new ticket!',
      // text: 'This cannot be undone',
      // icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.value) {
        this.onSubmit();
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return
      }
    })
  }
  pullTickets() {
  }
  playLottery() {
    if (document.querySelectorAll(".number-list li.active").length < 6) {
      let lengthOfSelected = document.querySelectorAll(".number-list li.active").length;
      let lengthToSelect = 6 - document.querySelectorAll(".number-list li.active").length;
      alert("You have selected only " + lengthOfSelected + " alphabet remaining " + lengthToSelect + " to go")
    } else {
      if (this.userTicket.length < 6) {
        // document.querySelectorAll(".number-list li.active").forEach(element => {
        //   this.userSelectedNumber.push(element.innerHTML)
        // });
        this.userTicket.push(this.userSelectedNumber);
        this.ticketCounter = this.ticketCounter + 1;
        this.user_ticket_submission_array.push(JSON.stringify({ combination: this.userSelectedNumber.join("") }));
        // this.user_ticket_submission_array.push({['ticket' + this.ticketCounter]: this.userSelectedNumber});

        this.userSelectedNumber = [];
        this.lotteryAmountTotal = this.userTicket.length * this.setting.ticketAmount;
        $(".number-list li.active").removeClass("active");
      } else {
        alert('Dear valued player, your cart cannot contain more than 6 items');
      }

    }
  }
  randomLottery() {
    this.userSelectedNumber = []
    $(".number-list li.active").removeClass("active");

    let randomNumberArray = [];
    let randomSelectedNumber = []
    // get array of all alphabet
    for (let index = 0; index < document.querySelectorAll(".number-list li").length; index++) {
      let randomNumber = Math.floor(Math.random() * document.querySelectorAll(".number-list li").length);
      randomNumberArray.push(randomNumber);
    }

    // get unique elements
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    // extract 6 array element
    let unique = randomNumberArray.filter(onlyUnique);
    for (let index = 0; index < 6; index++) {
      randomSelectedNumber.push(unique[index]);

    }

    randomSelectedNumber.forEach(element => {
      document.querySelectorAll(".number-list li")[element].classList.add('active');
    });

    document.querySelectorAll(".number-list li.active").forEach(element => {
      this.userSelectedNumber.push(element.innerHTML)
    });

    randomSelectedNumber = [];
  }
  removeCartItem(i) {
    this.userTicket.splice(i, 1);
    this.user_ticket_submission_array.splice(i, 1);
    this.lotteryAmountTotal = this.userTicket.length * this.setting.ticketAmount;
  }
  get f() { return this.ticketForm.controls; }

  submitLottery(txtref) {
    let lotteryObj = {
      amount: this.lotteryAmountTotal,
      game: JSON.stringify(this.user_ticket_submission_array),
      type: 'anon',
      trans_ref: txtref,

    }

    delete this.ticketForm.value.acceptTerms

    const ticketInput = Object.assign({}, lotteryObj, this.ticketForm.value);
    let query = gql`
		  mutation createAnonTicket($ticketInput: ticketInputType!) {
        createAnonTicket(ticketInput: $ticketInput, device: "anon") {
			  id
			  ticketId
			
			}
			}
		  `;

    this.ticketCounter = 0;
    this.api.postData(query, { ticketInput: ticketInput })
      .subscribe((res: any) => {
        if (res.data["createAnonTicket"] == null) {
          this.toastr.error(res.errors[0].message)
          // this.gotoWallet()

          return
        }
        this.submitted = false;
        this.userTicket = [];
        this.user_ticket_submission_array = [];
        this.toastr.success("Ticket purchased successfully", "Success")
        this.ticketForm.reset()

        // this.api.wallet.next(res.data["createAgentTicket"].agent);
        this.lotteryAmountTotal = 0;
      },
        error => {
          // this.toastr.success("Raffle Play Successfully", "Success")
        })

  }
  onSubmit() {
    this.submitted = true;
    if (this.ticketForm.value.acceptTerms != true) {

      alert('Please accept the terms and conditions')
      return
    }
    if(this.ticketForm.value.account_number){

    //   let name = [...this.ticketForm.value.firstname.trim().toUpperCase().split(" "), ...this.ticketForm.value.lastname.trim().toUpperCase().split(" ")];
    //   console.log(this.wordsAppear(name,this.resolvedBankInfo.account_name.trim()));
      
    // if (!this.wordsAppear(name,this.resolvedBankInfo.account_name.trim())) {
    
    //   this.toastr.error("Name on your account does not match your entered name", "Name Mismatch")
    //   return
    // }
    let name = this.ticketForm.value.firstname.toUpperCase() 

    if (name != this.resolvedBankInfo.account_name.toUpperCase()) {
      this.toastr.error("Name on your account does not match your entered name", "Name Mismatch")
      return
    }
    }
    // stop here if form is invalid
    if (this.ticketForm.invalid) {

      // this.submitted = false
      return;
    }

    if (!this.setting.sellStatus) {
      this.toastr.error("Tickets cannot be purchased at this time, pls try again later")
      return
    }

    this.payWithPaystack();
    // display form values on success
  }
  payWithPaystack() {
    var self = this;
    let email = "info@citizenslottery.com"
    if (this.ticketForm.value.email != "" || this.ticketForm.value.email == null) {
      email = this.ticketForm.value.email;
    }

    var handler = PaystackPop.setup({
      key: 'pk_live_10c58b4a6ae0c814f0bd09f3ad974cf3777ee312',
      // key: 'pk_test_29f6fe551380f423160f8b34b801f70e38c2d0e0',
      email: email.replace(/ /g, ''),
      amount: (100 * self.lotteryAmountTotal),
      currency: "NGN",
      // ref: ''+Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
      callback: function (response) {
        // self.deposit(response);
        self.submitLottery(response.reference);

        // self.modalRef.hide();

      },
      onClose: function () {
        // alert('window closed');
        // self.modalRef.hide();
      }
    });
    handler.openIframe();
  }
  getSetting() {
    let data = gql`
		  {
			getSetting {
        sellStatus
			 flashMessage
       liveLink
       liveStatus
       ticketAmount
			}
		  }
		`
    this.api.pullData(data, {})
      .subscribe(result => {
        this.setting = result.data["getSetting"];
        // 

        setInterval(() => {
          // $('.withdrawalTable').DataTable();
        }, 2000);
      },
        error => {
          // this.toastr.error("Oops, we could not get you user's data, please check your network and try again", "Unsuccessful")
        });
  }
  verifyAccount() {
    this.resolvedBankInfo = {}
    console.log(this.ticketForm.value.account_number);
    const inputElement = document.getElementById('fname') as HTMLInputElement;
          if (inputElement) {
              inputElement.disabled = false;
          }
    if (this.ticketForm.value.account_number.length == 10 &&  this.ticketForm.value.bank_name) {
      
      this.crud.verifyAccount(this.ticketForm.value.bank_name, this.ticketForm.value.account_number).subscribe(res => {
        console.log(res);
        this.resolvedBankInfo = res['data']
        // let name = [...this.ticketForm.value.firstname.trim().toUpperCase().split(" "), ...this.ticketForm.value.lastname.trim().toUpperCase().split(" ")];
        let name = this.ticketForm.value.firstname.toUpperCase() 
           this.ticketForm.patchValue({firstname: this.resolvedBankInfo.account_name})
        // if (!this.wordsAppear(name,this.resolvedBankInfo.account_name.trim())) {
          const inputElement = document.getElementById('fname') as HTMLInputElement;
          if (inputElement) {
              inputElement.disabled = true;
          }
        //   this.toastr.error("Name on your account does not match your entered name", "Name Mismatch")

        // }

        if (name != this.resolvedBankInfo.account_name.toUpperCase()) {

          // this.toastr.error("Name on your account does not match your entered name", "Name Mismatch")
        }
      }, err => {
        console.log(err);
        this.toastr.error("Your account number could not be resolved", "Incorrect Account Number")
      })
    }

  }
  wordsAppear(words, string) {
    for (let i = 0; i < words.length; i++) {
      console.log(words[i], string.split(" ").includes(words[i]));
        if (!string.split(" ").includes(words[i])) {
          
            return false;
        }
    }
    return true;
}
  makeGetRequest(): void {
    console.log('fetching data');

    const url = 'https://api.paystack.co/bank'; // Replace with your API endpoint
    const authToken = 'sk_live_794bd88cafff660831706579c41ffa5411aec652'; // Replace with your actual authorization token

    const headers = new HttpHeaders({
      Authorization: `Bearer ${authToken}`,
    });

    this.http.get(url, { headers }).subscribe(
      (response) => {

        // Process the response data here
        this.banks = response['data']
        console.log(this.banks)
      },
      (error) => {
        console.error('Error:', error);
        // Handle the error here
      }
    );
  }
}
