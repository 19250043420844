import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  search: any
  constructor(private crud: CrudService) { }

  record(data) {
    let query = gql`
    mutation createActivity ($activityInput: activityInputType!){
      createActivity(activityInput: $activityInput){
        id
      }
    }
     `
    this.crud.postData(query, { activityInput: data }).subscribe(result => {

    })
  }

  persist(text) {
    this.search = text
  }
  pullPersist() {
    return this.search;
  }
}
