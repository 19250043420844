
import { Component, ViewChild, OnInit } from '@angular/core';
import { LiveChatWidgetModel, LiveChatWidgetApiModel } from '@livechat/angular-widget';
import gql from 'graphql-tag';
import { CrudService } from './services/crud.service';

declare const $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  countDownDays: number;
  countDownHours: number;
  countDownMinutes: number;
  countDownSeconds: number;

  public isLiveChatWidgetLoaded: boolean = false;
  public liveChatApi: LiveChatWidgetApiModel;
  @ViewChild('liveChatWidget', { static: false }) public liveChatWidget: LiveChatWidgetModel;

  dateTime
  title = 'Citizen Lottery';
  draw: any;
constructor(private crudService: CrudService){

}
  ngOnInit() {

    this.getSetting()
  }

  getSetting() {

    let data = gql`
		  {
        availableDraws {
		drawDate
			}
		  }
		`
    this.crudService.pullData(data, {})
      .subscribe(result => {
        this.draw = result.data["availableDraws"][0];
        
        
        
        if(this.draw){
          $('#countDownModal').modal('show')
          setInterval(() => {
            this.calcCountDownTime(this.draw.drawDate);
          }, 1000)
        }
      },
        error => {
          // this.toastr.error("Oops, we could not get you user's data, please check your network and try again", "Unsuccessful")
        });
  }
  onChatLoaded(api: LiveChatWidgetApiModel): void {
    this.liveChatApi = api;
    this.isLiveChatWidgetLoaded = true;

    // Sometimes it can happen that LC_Invite is is still being loaded when onChatLoaded is called. To ensure that LC_Invite is loaded you can give additional check to onChatLoaded function:
    // api.on_after_load = () => {
    //   this.liveChatApi = api;
    //   this.isLiveChatWidgetLoaded = true;
    // };
  }

  onChatWindowMinimized() {
  }

  onChatWindowOpened() {

  }

  openChatWindow(): void {
    if (this.isLiveChatWidgetLoaded) {
      this.liveChatWidget.openChatWindow();

      // You can also use methods directly on liveChatApi instance
      // for more details plese read our documentation 
      // https://developers.livechatinc.com/docs/extending-ui/extending-chat-widget/javascript-api/#methods
      // this.liveChatApi.open_chat_window();
    };
  }

  hideChatWindow() {
    if (this.isLiveChatWidgetLoaded) {
      this.liveChatWidget.minimizeChatWindow();

      // You can also use methods directly on liveChatApi instance
      // for more details plese read our documentation 
      // https://developers.livechatinc.com/docs/extending-ui/extending-chat-widget/javascript-api/#methods
      // this.liveChatApi.minimize_chat_window();
    };
  }

  calcCountDownTime(date) {
    // Set the date we're counting down to
    var countDownDate = new Date(date).getTime();

    // Get todays date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    this.countDownDays = days;
    this.countDownHours = hours;
    this.countDownMinutes = minutes;
    this.countDownSeconds = seconds;
    // Display the result in the element with id="demo"
    // document.getElementById("demo").innerHTML = days + "d " + hours + "h "
    // + minutes + "m " + seconds + "s ";

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval();
      $('#countDownModal').modal('hide')
      // document.getElementById("demo").innerHTML = "EXPIRED";
    }

  }
}
