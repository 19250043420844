import { faGamepad, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';
import { SubjectService } from '../../services/subject.service';
import { Router } from '@angular/router';
import { log } from 'console';

declare const $: any;
declare const PaystackPop: any;
@Component({
  selector: 'app-dashboard-content',
  templateUrl: './dashboard-content.component.html',
  styleUrls: ['./dashboard-content.component.css']
})
export class DashboardContentComponent implements OnInit {
  lotteryData = [];
  transactionHistory: any;
  credit: number;
  debit: number;
  walletBalance: any;
  user_ticket_submission_array: any = [];
  ticketCounter = 0;
  userProfile: any;
  tickets: any;
  singleTicket: any;
  ticketScheduledDate = '';
  draws: any;
  fundButton = true;
  fundWallet = false;
  lastDraws: any;
  lastDrawsDate: any;
  faTimesCircle = faTimesCircle
  isModalShown
  userSelectedNumber
  setting: any;
  post:any = {}

  constructor(private crudService: CrudService, private router: Router, private subject: SubjectService, private toastr: ToastrService) {
    this.tickets = [{ createdAt: '', amount: '', playStatus: '', ticketId: '', games: [] }];
    this.singleTicket = { createdAt: '', ticketId: '', amount: '', games: [], draw: { drawDate: '', drawType: { name: '' } } };
    this.draws = [{ id: "", drawType: { name: "" }, drawDate: "" }];
    this.walletBalance = {
      wallet_balance: ''
    }
    this.getUserObj()
    this.userSelectedNumber = [];
  }

  userTicket = [];
  ;
  lotteryAmountTotal: Number;
  ngOnInit() {
    var top = this;
    $(".number-list li").on('click', function () {
      if (document.querySelectorAll(".number-list li.active").length < 6) {
        $(this).toggleClass("active");
        if ($(this).hasClass("active")) {
          top.userSelectedNumber.push($(this).html())
        } else {
          top.userSelectedNumber.splice(top.userSelectedNumber.indexOf($(this).html()), 1)
        }
        return
      } else if (($(this).hasClass("active"))) {
        $(this).removeClass("active");
        top.userSelectedNumber.splice(top.userSelectedNumber.indexOf($(this).html()), 1)
      } else {
        $(this).removeClass("active");
        if (document.querySelectorAll(".number-list li.active").length == 6) {
          alert("You have reached the maximum number of Raffle Alphabets Allowed")
        }
      }
    });

    // this.getTransactionHistory()
    this.pullTickets()
    this.pullLastDraws()
    this.getSetting()
  }

  getUserObj() {
    this.userProfile = JSON.parse(localStorage.getItem('user'))
  }

  playLottery() {
    if (document.querySelectorAll(".number-list li.active").length < 6) {
      let lengthOfSelected = document.querySelectorAll(".number-list li.active").length;
      let lengthToSelect = 6 - document.querySelectorAll(".number-list li.active").length;
      alert("You have selected only " + lengthOfSelected + " alphabet remaining " + lengthToSelect + " to go")
    } else {
      if (this.userTicket.length < 6) {
        // document.querySelectorAll(".number-list li.active").forEach(element => {
        //   this.userSelectedNumber.push(element.innerHTML)
        // });
        this.userTicket.push(this.userSelectedNumber);
        this.ticketCounter = this.ticketCounter + 1;
        this.user_ticket_submission_array.push(JSON.stringify({ combination: this.userSelectedNumber.join("") }));
        // this.user_ticket_submission_array.push({['ticket' + this.ticketCounter]: this.userSelectedNumber});

        this.userSelectedNumber = [];
        this.lotteryAmountTotal = this.userTicket.length * this.setting.ticketAmount;
        $(".number-list li.active").removeClass("active");
      } else {
        alert('Dear valued player, your cart cannot contain more than 6 items');
      }

    }
  }
  randomLottery() {
    this.userSelectedNumber = []
    $(".number-list li.active").removeClass("active");

    let randomNumberArray = [];
    let randomSelectedNumber = []
    // get array of all alphabet
    for (let index = 0; index < document.querySelectorAll(".number-list li").length; index++) {
      let randomNumber = Math.floor(Math.random() * document.querySelectorAll(".number-list li").length);
      randomNumberArray.push(randomNumber);
    }

    // get unique elements
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    // extract 6 array element
    let unique = randomNumberArray.filter(onlyUnique);
    for (let index = 0; index < 6; index++) {
      randomSelectedNumber.push(unique[index]);

    }

    randomSelectedNumber.forEach(element => {
      document.querySelectorAll(".number-list li")[element].classList.add('active');
    });

    document.querySelectorAll(".number-list li.active").forEach(element => {
      this.userSelectedNumber.push(element.innerHTML)
    });

    randomSelectedNumber = [];
  }

  submitLottery() {
    
    
    if(this.post.acceptTerms != true){
      
      
      alert('Please accept the terms and conditions')
      return
  }
    let lotteryObj = {
      userId: this.userProfile.id,
      amount: this.lotteryAmountTotal,
      game: JSON.stringify(this.user_ticket_submission_array),
      type: 'user'
      // drawId: this.ticketScheduledDate,
    }
    let query = gql`
      mutation createTicket($ticketInput: ticketInputType!) {
        createTicket(ticketInput: $ticketInput, device: "web") {
          id
          ticketId
          user{
            wallet_balance
          }
        }
        }
      `;

    this.ticketCounter = 0;
    this.crudService.postData(query, { ticketInput: lotteryObj })
      .subscribe((res: any) => {
        if (res.data["createTicket"] == null) {
          this.toastr.error(res.errors[0].message)
          // this.gotoWallet()

          return
        }

        this.toastr.success("Ticket Bought Successfully", "Success")
        this.pullTickets()
        this.subject.wallet.next(res.data["createTicket"].user);

      },
        error => {
          // this.toastr.success("Raffle Play Successfully", "Success")
        })

    this.userTicket = [];
    this.user_ticket_submission_array = [];
  }

  removeCartItem(i) {
    this.userTicket.splice(i, 1);
    this.user_ticket_submission_array.splice(i, 1);
    this.lotteryAmountTotal = this.userTicket.length * this.setting.ticketAmount;
  }

  pullTickets() {
    let data = gql`
      query ticketsByPlay($userId:ID!){
        ticketsByPlay(userId: $userId) {
          createdAt
          amount
          games
          playStatus
          ticketId
          totalWinning
          draw{
            combination
            drawDate
            drawType{
              name
            }
          }
        }
      }
    `
    this.crudService.pullData(data, { userId: this.userProfile.id, played: "1" })
      .subscribe(result => {
        this.tickets = result.data["ticketsByPlay"];
        setInterval(() => {
          $('.disputeList').DataTable();
        }, 2000);
      },
        error => {
          // this.toastr.error("Oops, we could not get you ticket's data, please check your network and try again", "Unsuccessful")
        });
  }

  ticketDetails(data) {

    let gameArrToDisplay = this.ticketWins(data);
    this.singleTicket = {
      createdAt: data.createdAt,
      ticketId: data.ticketId,
      amount: data.amount,
      games: gameArrToDisplay,
      playStatus: data.playStatus,
      totalWinning: data.totalWinning,
      draw: {
        // ticketDraw: data.draw.combination.split(""),
        drawDate: data.draw.drawDate,
        // drawType: {
        //   name: data.draw.drawType.name
        // }
      }
    }
    if (data.draw.combination) this.singleTicket.draw.ticketDraw = data.draw.combination.split("");
    $('#exampleModalCenter').modal('show')
  }

  ticketWins(data) {
    let gameArr = JSON.parse(data.games);
    let combinationArr = []
    let gameArrToDisplay = [];
    let draw = [];
    let gameDraw;
    if (data.draw.combination != null) {
      gameDraw = data.draw.combination.split("");

      gameDraw.forEach(element => {
        draw.push({ letter: element, status: "unused" })
      });
    }

    gameArr.forEach(element => {
      combinationArr.push(element.combination.split(""))
    });

    for (let i = 0; i < combinationArr.length; i++) {
      let combObjt = []
      combinationArr[i].forEach(element => {
        combObjt.push({ status: "pending", letter: element })
      });;
      gameArrToDisplay.push(combObjt)
    }
    for (let index = 0; index < gameArrToDisplay.length; index++) {
      draw.forEach(element => {
        element.status = "unused";
      });

      draw.forEach(element => {
        gameArrToDisplay[index].forEach(innerElement => {
          if (element.letter == innerElement.letter && element.status == "unused" && innerElement.status == "pending") {
            innerElement["status"] = "win";
            element.status = "used"
          }
        });
      });

    }

    return gameArrToDisplay
  }

  checkWin(item) {
    if (this.singleTicket.playStatus == "1") {
      return this.singleTicket.draw.ticketDraw.includes(item)
    }
  }
  getScheduleDate(data) {
    this.ticketScheduledDate = data.target.value;
  }

  pullLastDraws() {
    let data = gql`
      {
        lastDraw {
          id
          combination
          drawDate
        }
      }
    `
    this.crudService.pullData(data, {})
      .subscribe(result => {
        if (result.data["lastDraw"].combination != null) {

          this.lastDraws = result.data["lastDraw"].combination.split("");
          this.lastDrawsDate = result.data["lastDraw"].drawDate;

        }
      },
        error => {
          // this.toastr.error("Oops, we could not get you draw's data, please check your network and try again", "Unsuccessful")
        });
  }

  gotoWallet() {
    this.router.navigate(['/dashboard/my-wallet']);
  }
  getSetting() {
    let data = gql`
		  {
			getSetting {
			 flashMessage
       liveLink
       liveStatus
       ticketAmount
       ticketDetails
			}
		  }
		`
    this.crudService.pullData(data, {})
      .subscribe(result => {
        this.setting = result.data["getSetting"];
        // 

        setInterval(() => {
          // $('.withdrawalTable').DataTable();
        }, 2000);
      },
        error => {
          // this.toastr.error("Oops, we could not get you user's data, please check your network and try again", "Unsuccessful")
        });
  }
}
