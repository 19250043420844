import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
declare const $: any;
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';
import { ActivityService } from 'src/app/services/activity.service';
import { Router } from '@angular/router';
import { ScrollService } from 'src/app/services/scroll.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  spinner: boolean;
  message: any;
  error: string;
  inproperDate: boolean;
  year: any = [];
  month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  day = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];
  postForm: any = {};
  banks: any;
  resolvedBankInfo: any = {};

  constructor(private scrollTopService: ScrollService, private formBuilder: FormBuilder, private http: HttpClient, private crud: CrudService, private toastr: ToastrService, private activity: ActivityService, private router: Router) {
    this.postForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      password: ["", [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit() {

    $(".toggle-password").click(function () {

      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
    this.getYear()
    this.makeGetRequest()

    this.scrollTopService.setScrollTop();
    $("#preloader").delay(300).animate({
      "opacity": "0"
    }, 500, function () {
      $("#preloader").css("display", "none");
    });

  }
  get f() {
    return this.postForm.controls;
  }
  async getYear() {
    let currentYear = new Date().getFullYear();
    let dobYear = []

    // for (let i = 1900; i <= currentYear; i++) {
    //   dobYear.push(currentYear.toString())
    //   currentYear = currentYear - 1;
    // }
    let startYear
    startYear = startYear || 1900;
    while (startYear <= currentYear) {
      this.year.push(startYear++);
    }
    this.year.reverse();
    // return years;
    // for (currentYear; currentYear <= 1990; currentYear--) {
    // }

    // this.year = dobYear;

  }

  submitRegistrationForm(data) {

    this.spinner = true;
    this.message = null
    let dob = data.value.year + '-' + data.value.month + '-' + data.value.day;
    // if(data.value.pass.length < 6) this.toastr.error("Password must be more than 6 characters")

    if (data.value.acceptTerms != true) {

      alert('Please accept the terms and conditions')
    }
    if (data.value.pass == data.value.re_pass) {
      let userObj = {
        firstname: data.value.f_name,
        lastname: data.value.l_name,
        // username: "null",
        phone: data.value.phone,
        email: (data.value.email).toLowerCase(),
        password: data.value.pass,
        dob: dob,
      }

      //       let name = [...this.postForm.f_name.toUpperCase().trim().split(" "), ...this.postForm.l_name.toUpperCase().trim().split(" ")];
      //       console.log(this.wordsAppear(name,this.resolvedBankInfo.account_name.trim()));

      //     if (!this.wordsAppear(name,this.resolvedBankInfo.account_name.trim())) {

      //       this.toastr.error("Name on your account does not match your entered name", "Name Mismatch")
      // return
      //     }
      let name = this.postForm.f_name.toUpperCase() +' '+ this.postForm.l_name.toUpperCase() 
      if (name != this.resolvedBankInfo.account_name.toUpperCase()) {
        this.toastr.error("Name on your bank account does not match the name you entered", "Name Mismatch")
        return
      }
      let query = gql`
      mutation createUser($userInput: userInputType!) {
        createUser(userInput: $userInput, device: "web") {
          id
        }
        }
      `;

      this.crud.postData(query, { userInput: userObj }).subscribe(result => {
        if (!result.data['createUser']) {
          this.spinner = false;
          // this.error = result.errors[0].message;
          this.toastr.warning("This user is already registered", "User")
        }
        let activityInput = {
          type: 'User',
          operation: 'registration',
          userId: result.data['createUser'].id,
        }
        this.activity.record(activityInput);
        this.toastr.success("Check e-mail inbox for verification link to gain full access. Please check spam/junk folder if mail doesn't drop in inbox", 'Account creation successful!', {
          tapToDismiss: true
        });
        this.router.navigate(['/login']);
        this.spinner = false;
      },
        error => {
          this.spinner = false;
          this.toastr.error(error.message, 'Error');
        })
    } else {
      this.toastr.error('Password and Comfirm Password fields did not match', 'Error')
    }
  }

  checkDate(data: any) {
    if (data.value.year && data.value.month && data.value.day) {
      let dob = data.value.year + '-' + data.value.month + '-' + data.value.day;
      this.inproperDate = false;

      let selectedDate = '' + new Date(dob);
      let currentDate = '' + new Date();

      let d = Date.parse(selectedDate);
      let n = Date.parse(currentDate);

      let checkYear = (n - d) / 31536000000

      if (checkYear < 18) {
        data.form.controls.year.status = "INVALID";
        data.form.controls.month.status = "INVALID";
        data.form.controls.day.status = "INVALID";
        this.inproperDate = true;
        this.toastr.warning("Your age cannnot be less than 18", "Incorrect Date")
        data.form.status = "INVALID";
      }
    }
  }

  verifyAccount() {
    this.resolvedBankInfo = {}
    const inputElement = document.getElementById('fname') as HTMLInputElement;
          if (inputElement) {
              inputElement.disabled = false;
          }
          const inputElement2 = document.getElementById('lname') as HTMLInputElement;
          if (inputElement2) {
              inputElement2.disabled = false;
          }
    if (this.postForm.account_number.length == 10 && this.postForm.bank_name) {

      this.crud.verifyAccount(this.postForm.bank_name, this.postForm.account_number).subscribe(res => {
        this.resolvedBankInfo = res['data']
        // let name = [...this.postForm.f_name.toUpperCase().trim().split(" "), ...this.postForm.l_name.toUpperCase().trim().split(" ")];
        // let name = this.postForm.l_name.toUpperCase() + ' ' + this.postForm.f_name.toUpperCase()
        // if (!this.wordsAppear(name,this.resolvedBankInfo.account_name.trim())) {
          //   this.toastr.error("Name on your account does not match your entered name", "Name Mismatch")
          // }
          let first = this.resolvedBankInfo.account_name.split(' ');
          console.log(first);
          const cleanedNames = first.filter(name => name !== '');
          
          this.postForm.f_name = cleanedNames[0] ? cleanedNames[0] : '';
          this.postForm.l_name = cleanedNames[1] ? cleanedNames[1] + (cleanedNames[2] ? ' ' + cleanedNames[2] : '') : '';
    
          const inputElement = document.getElementById('fname') as HTMLInputElement;
          if (inputElement) {
              inputElement.disabled = true;
          }
          const inputElement2 = document.getElementById('lname') as HTMLInputElement;
          if (inputElement2) {
              inputElement2.disabled = true;
          }
        // if (name != this.resolvedBankInfo.account_name.toUpperCase()) {
        //   this.toastr.error("Name on your account does not match your entered name", "Name Mismatch")
        // }
      }, err => {
        console.log(err);
        this.toastr.error("Your account number could not be resolved", "Incorrect Account Number")
      })
    }

  }

  wordsAppear(words, string) {
    for (let i = 0; i < words.length; i++) {
      console.log(words[i], string.split(" ").includes(words[i]));
      if (!string.split(" ").includes(words[i])) {

        return false;
      }
    }
    return true;
  }

  makeGetRequest(): void {
    console.log('fetching data');

    const url = 'https://api.paystack.co/bank'; // Replace with your API endpoint
    const authToken = 'sk_live_794bd88cafff660831706579c41ffa5411aec652'; // Replace with your actual authorization token

    const headers = new HttpHeaders({
      Authorization: `Bearer ${authToken}`,
    });

    this.http.get(url, { headers }).subscribe(
      (response) => {

        // Process the response data here
        this.banks = response['data']
        console.log(this.banks)
      },
      (error) => {
        console.error('Error:', error);
        // Handle the error here
      }
    );
  }
}
