import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  // base_url:any = "" 
  constructor(private apollo: Apollo, private http: HttpClient) { }
  pullData(query, data) {
    return this.apollo
      .query({
        query: query,
        variables: data,
        fetchPolicy: "network-only",
        errorPolicy: 'all'

      })

  }
  postData(query, postData) {
    return this.apollo
      .mutate({
        mutation: query,
        variables: postData,
        errorPolicy: 'all'

      })
  }
  convertUnixTimestampToISO(unixTimestamp) {
    // 
    
    if (unixTimestamp == null) return ''
    if (unixTimestamp == '') return ''
    const milliseconds = parseInt(unixTimestamp); // No need to multiply by 1000, as the provided timestamp is already in milliseconds
    const date = new Date(milliseconds);
    return date.toISOString();
  }

  verifyAccount(bankCode,accountNumber){
    const authToken = 'sk_live_794bd88cafff660831706579c41ffa5411aec652'; // Replace with your actual authorization token
	
		const headers = new HttpHeaders({
		  Authorization: `Bearer ${authToken}`,
		});
    return this.http.get(`https://api.paystack.co/bank/resolve?account_number=${accountNumber}&bank_code=${bankCode}`, {headers});
  }
}
