import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivityService } from 'src/app/services/activity.service';
import { CrudService } from 'src/app/services/crud.service';
import gql from 'graphql-tag';
import { ScrollService } from 'src/app/services/scroll.service';
declare const $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  spinner: boolean = false;
  message: string;

  loginForm = true;
  postForm: any;
  submitted: boolean;
  error: string;
  recoveryForm: any;
  recoveryEmailForm = false;
  token: any;
  showPassword: boolean = false;
  constructor(private scrollTopService: ScrollService, private route: ActivatedRoute, private toastr: ToastrService, private formBuilder: FormBuilder, private activity: ActivityService, private router: Router, private crud: CrudService) {
    this.postForm = this.formBuilder.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
    this.recoveryForm = this.formBuilder.group({
      email: ["", [Validators.required]]
    });

    let token = localStorage.getItem('token');
    if (token) {
      this.router.navigate(['/dashboard']);
    }
  }
  get f() {
    return this.postForm.controls;
  }

  get r() {
    return this.recoveryForm.controls;
  }
  ngOnInit() {
    $(".toggle-password").click(function () {

      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });

  }
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  login() {
    this.submitted = true;
    // this.showMsg = false;
    // 
    if (this.postForm.invalid) {
      // 
      // this.submitted = false;
      return;
    }

    let obj = {
      email: (this.postForm.value.email).toLowerCase(),
      password: this.postForm.value.password
    }

    let query = gql`
    mutation login(
      $email: String!
      $password: String!, ){
    login(email: $email, password: $password, device: "web") {
      token,
      user{
        id 
        name
        email
        username
        phone
        uuid
        
      }
    }
      }
    `;
    this.crud.postData(query, obj).subscribe(result => {
      if (!result.data['login']) {
        this.error = result.errors[0].message;
      }

      localStorage.setItem('token', result.data['login'].token);
      localStorage.setItem('user', JSON.stringify(result.data['login'].user));

      let activityInput = {
        type: 'Access',
        operation: 'login',
        userId: result.data['login'].user.id,
      }
      this.activity.record(activityInput);
      this.router.navigate(['/dashboard'])
    })

  }

  sendEmail() {
    this.submitted = true;
    // this.showMsg = false;
    // 
    let obj = {
      email: (this.recoveryForm.value.email).toLowerCase()
    }
    if (this.recoveryForm.invalid) {
      // 
      // this.submitted = false;
      return;
    }

    // 
    let query = gql`
    mutation resetPassword($email: String!) {
      resetPassword(email:$email) {
        id
      }
      }
    `;
    this.crud.postData(query, obj)
      .subscribe(result => {

        this.toastr.success('Check your email for confirmation mail', 'Success!');
      },
        error => {
          this.toastr.error('Ooops, kindly try again or check your network connection', 'Failed');
        })
  }

}
