import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lottery-history',
  templateUrl: './lottery-history.component.html',
  styleUrls: ['./lottery-history.component.css']
})
export class LotteryHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
