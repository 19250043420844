import { Component, OnInit } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';
import { ScrollService } from 'src/app/services/scroll.service';
declare const $: any;



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  countDownDays: number;
  countDownHours: number;
  countDownMinutes: number;
  countDownSeconds: number;
  lastDraws: any;
  allDraws: any;
  message: any;

  constructor(private crudService: CrudService, private toastr: ToastrService, private scrollTopService: ScrollService) {
    this.allDraws = [];
  }

  ngOnInit() {
    // this.getAllDraw()
    // this.pullLastDraws()
    this.getSetting();
    this.scrollTopService.setScrollTop();
    $(function () {
      $(".lottery-result-row").slice(0, 4).show();
      $('.load-more').on('click', function () {
        $(this).addClass('load-more--loading');
        setTimeout(function () {
          $('.load-more').removeClass('load-more--loading');
        }, 1000);
        $(".load-more").on('click', function (e) {
          e.preventDefault();
          $(".lottery-result-row:hidden").slice(0, 4).slideDown();
          if ($(".lottery-result-row:hidden").length == 0) {
            $("#load").fadeOut('slow');
          }
          // $('html,body').animate({
          //     scrollTop: $(this).offset().top
          // }, 1500);
        });
      });

      $("#preloader").delay(300).animate({
        "opacity": "0"
      }, 500, function () {
        $("#preloader").css("display", "none");
      });
    });
  }

  calcCountDownTime(date) {
    // Set the date we're counting down to
    var countDownDate = new Date(date).getTime();

    // Get todays date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);


    this.countDownDays = days;
    this.countDownHours = hours;
    this.countDownMinutes = minutes;
    this.countDownSeconds = seconds;
    // Display the result in the element with id="demo"
    // document.getElementById("demo").innerHTML = days + "d " + hours + "h "
    // + minutes + "m " + seconds + "s ";

    // If the count down is finished, write some text
    // if (distance < 0) {
    //   clearInterval(x);
    //   document.getElementById("demo").innerHTML = "EXPIRED";
    // }
  }

  getAllDraw() {
    let data = gql`
      {
        completedDraws {
          id
          drawDate
          combination
        } 
        draws{
          id 
          drawDate
        }
      }
    `
    this.crudService.pullData(data, {})
      .subscribe(result => {
        let draws = result.data['completedDraws']
        let alldraws = result.data['draws']
        draws.forEach(element => {
          this.allDraws.push({ combination: element.combination.split(""), drawDate: element.drawDate });
        });

        setInterval(() => {
          $('.shortlistedApplicant').DataTable();
        }, 2000);
        setInterval(() => {
          this.calcCountDownTime(alldraws[alldraws.length - 1].drawDate)
        }, 1000)
      },
        error => {
          this.toastr.error("Oops, we cannot fetch your data at this time, kindly try again", "Unsuccessful")
        });
  }

  pullLastDraws() {
    let data = gql`
      {
        lastDraw {
          id
          combination
        }
      }
    `
    this.crudService.pullData(data, {})
      .subscribe(result => {
        if (result.data["lastDraw"].combination != null) {

          this.lastDraws = result.data["lastDraw"].combination.split("");

        }
      },
        error => {
          // this.toastr.error("Oops, we could not get you draw's data, please check your network and try again", "Unsuccessful")
        });
  }
  getSetting() {
    let data = gql`
		  {
			getSetting {
			 flashMessage
       liveLink
       liveStatus
			}
		  }
		`
    this.crudService.pullData(data, {})
      .subscribe(result => {
        this.message = result.data["getSetting"];
        

        setInterval(() => {
          // $('.withdrawalTable').DataTable();
        }, 2000);
      },
        error => {
          // this.toastr.error("Oops, we could not get you user's data, please check your network and try again", "Unsuccessful")
        });
  }
}
