import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare const $:any;
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  
  user;
  token: string;
  constructor(private router: Router) {
    this.token = localStorage.getItem("token")
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  ngOnInit() {
  }
  logout() {
    let activityInput = {
      type: 'Access',
      operation: 'logout',
      userId: this.user['id'],
    }
    // this.activity.record(activityInput);

    this.user = null;
    localStorage.removeItem('user');
    localStorage.removeItem('token');

    this.router.navigate(['/home'])
  }
}