export const environment = {
  production: false,
  // serverUrl: "https://api.citizenslottery.com/",
  serverUrl: "https://clownfish-app-ys3sj.ondigitalocean.app/",
  // serverUrl: "http://localhost:4001/",

  log: true,
  flags: {
    useNewHeader: true
  }
};
