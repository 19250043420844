import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { NgForm } from '@angular/forms';
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { SubjectService } from '../../services/subject.service';
declare const $: any;
declare const PaystackPop: any;
@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {
  transactionHistory: any;
  credit: number;
  debit: number;
  walletBalance: any;
  bankDetails: any;
  fundWallet: any;
  fundButton: any;
  userProfile: any;
  modalRef: any;
  @ViewChild('autoShownModal', { static: false }) autoShownModal: ModalDirective;
  isModalShown = false;
  constructor(private crudService: CrudService, private subject: SubjectService, private router: Router, private modalservice: BsModalService, private toastr: ToastrService) {
    this.bankDetails = {
      other_details: {
        account_name: '',
        account_no: ''
      },
      bank: {
        name: ''
      }
    }

    this.walletBalance = {
      wallet_balance: ""
    }
    this.getUserObj()
  }

  ngOnInit() {
    this.getTransactionHistory(this.userProfile.id)
    this.getUserDetails(this.userProfile.id)
    this.getUserAcctDetails(this.userProfile.id)
  }

  getUserObj() {
    this.userProfile = JSON.parse(localStorage.getItem('user'))
  }

  getTransactionHistory(id) {
    let data = gql`
    query userTransactions($userId: ID!){
      userTransactions (userId: $userId){
        id
        trans_ref
        trans_no
        status
        amount
        createdAt
        type
        rt
      }
    }
  `;
    this.crudService.pullData(data, { userId: id })
      .subscribe(result => {
        this.transactionHistory = result.data['userTransactions']
        setInterval(() => {
          $('.walletTransactionList').DataTable();
        }, 2000);
      },
        error => {
          this.toastr.error("Oops, we cannot fetch your data at this time, kindly try again", "Unsuccessful")
        });
  }
  getUserDetails(id) {
    let data = gql`
    query user($id: ID!){
      user (id: $id){
        wallet_balance
      }
    }
  `;
    this.crudService.pullData(data, { id: id })
      .subscribe(result => {
        this.walletBalance = result.data['user']
      },
        error => {
          this.toastr.error("Oops, we cannot fetch your data at this time, kindly try again", "Unsuccessful")
        });
  }

  payWithPaystack(data: NgForm) {
    var self = this;
    sessionStorage.setItem("amount", "" + data.value.amount)

    var handler = PaystackPop.setup({
      key: 'pk_live_10c58b4a6ae0c814f0bd09f3ad974cf3777ee312',
      // key: 'pk_test_29f6fe551380f423160f8b34b801f70e38c2d0e0',
      email: this.userProfile.email,
      amount: (100 * data.value.amount),
      currency: "NGN",
      // ref: ''+Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
      callback: function (response) {
        self.deposit(response);

        // self.modalRef.hide();

      },
      onClose: function () {
        // alert('window closed');
        self.modalRef.hide();
      }
    });
    handler.openIframe();
  }
  deposit(data) {
    let transactionData = {
      trans_ref: data.reference,
      status: data.status,
      amount: sessionStorage.getItem('amount'),
      trans_no: data.transaction,
      userId: this.userProfile.id,
      type: "Deposit"
    }
    this.saveTransaction(transactionData)
  }
  withdrawNow(data: NgForm) {
    if(data.value.amount > this.walletBalance.wallet_balance ) {

      this.toastr.error("Insufficient Balance", "Unsuccessful");
      return
    }

    let transactionData = {

      status: "pending",
      amount: ""+data.value.amount,
      userId: this.userProfile.id,
      type: "Withdrawal",

    }
    // this.saveTransaction(transactionData)
    let query = gql`
    mutation withdraw( $amount: String!, $userId: String!, ) {
      withdraw( amount:$amount, userId:$userId, device: "web") {
        wallet_balance
      }
      }
    `;

    this.crudService.postData(query, transactionData)
      .subscribe((result) => {
        this.walletBalance = result.data['addtoWallet'];
        this.toastr.success("Withdrawal request sent", "Successful");

        this.getTransactionHistory(this.userProfile.id);
        this.subject.wallet.next(this.walletBalance);
        this.modalRef.hide();
      })
  }
  cancelWithdrawals(item) {
    // this.userName = name
    

    let data = gql`
    query cancelWithdrawal($transId:ID!) {
      cancelWithdrawal(transId:$transId) {
        wallet_balance

        }
      }
      `
    this.crudService.pullData(data, { transId: item })
      .subscribe(result => {
        this.toastr.success("Withdrawal Cancelled", "Successful");
        this.getTransactionHistory(this.userProfile.id)

      },
        error => {
          // this.toastr.error("Oops, we cannot fetch your data at this time, kindly try again", "Unsuccessful")
        });
  }
  saveTransaction(transactionData) {

    let query = gql`
      mutation addtoWallet($type:String!, $trans_ref: String, $status: String!, $amount: String!, $userId: String!, $trans_no: String, ) {
        addtoWallet(type:$type, trans_ref: $trans_ref, status: $status, amount:$amount, userId:$userId, trans_no:$trans_no, device: "web") {
          wallet_balance
        }
        }
      `;

    this.crudService.postData(query, transactionData)
      .subscribe((result) => {
        this.walletBalance = result.data['addtoWallet'];
        this.toastr.success("Wallet Deposited successfully", "Successful");

        this.getTransactionHistory(this.userProfile.id);
        this.subject.wallet.next(this.walletBalance);
        this.modalRef.hide();
      })
  }

  openModal(template: TemplateRef<any>, type) {
    if (type == "withdraw") {
      if (this.bankDetails.account_number == null) {
        this.toastr.error('Setup Bank Account');
        this.router.navigate(['/dashboard/edit-profile']);
        return
      }
    }
    this.modalRef = this.modalservice.show(template, { class: 'modal-sm' });
  }

  getUserAcctDetails(id) {
    let data = gql`
    query user($id: ID!){
      user (id: $id){
        account_number
        first_name
        last_name
        bank_name
      }
    }
  `;
    this.crudService.pullData(data, { id: id })
      .subscribe(result => {
        this.bankDetails = result.data['user']
      },
        error => {
          this.toastr.error("Oops, we cannot fetch your data at this time, kindly try again", "Unsuccessful")
        });
  }

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }
}
