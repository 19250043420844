import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CrudService } from '../services/crud.service';
import gql from 'graphql-tag';
declare const $: any;

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
}) 
export class TransactionsComponent implements OnInit {
  medium:any
  criteria: any;
  post: any ={}
  selected: any;
  transactionHistory: any;
  draws:any 
  currentPage = 1;
  pageSize = 40;
  items: any[] = [];
  constructor(private crudService:CrudService, private http:HttpClient ) {
    this.medium = [
			'user', 'guest', 'agent'
		]

   }

  ngOnInit() {
    this.getAllDraw(this.currentPage, this.pageSize)
  }

  selectType(){
    this.selected =  this.criteria.find((element) => element.title ==  this.post.type);
  }

  getAllDraw(page, pageSize){
	this.http.get('http://localhost:4001/api/tickets/653cbd7189d486de82e61789?page='+page+'&pageSize='+pageSize).subscribe((res)=>{
		
		const newItems = res['items'];

		// Check if there are new items
		if (newItems.length > 0) {
		  // Concatenate the new items to the existing list
		  this.items = [...this.items, ...newItems];
		  
  
		  // Increment the page for the next request
		  this.currentPage++;
  
		  // Continue fetching data in a loop
		  this.getAllDraw(this.currentPage, this.pageSize);
		}
	})
  }
}
