import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
import { CrudService } from 'src/app/services/crud.service';
import gql from 'graphql-tag';
declare const $: any;
@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {
  allDraws: any;

  constructor(private scrollTopService: ScrollService, private crudService: CrudService) {
    this.allDraws = []
  }

  ngOnInit() {
    this.getAllDraw()
    this.scrollTopService.setScrollTop();
    $("#preloader").delay(300).animate({
      "opacity": "0"
    }, 500, function () {
      $("#preloader").css("display", "none");
    });
  }

  getAllDraw() {
    let data = gql`
      {
        completedDraws {
          id
          drawDate
          combination
          fourIds
          fiveIds
        } 
      }
    `
    this.crudService.pullData(data, {})
      .subscribe(result => {
        let draws = result.data['completedDraws']
        draws.forEach(element => {
          this.allDraws.push({ combination: element.combination.split(""), drawDate: element.drawDate, fourIds: JSON.parse(element.fourIds) });
        });


        

        setTimeout(() => {
          $('#lotteryTable').DataTable({ "ordering": false });
        }, 2000);
      },
        error => {
          // this.toastr.error("Oops, we cannot fetch your data at this time, kindly try again", "Unsuccessful")
        });
  }

}
