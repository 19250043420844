import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './general interface/home/home.component';
import { LoginComponent } from './general interface/login/login.component';
import { ResultComponent } from './general interface/result/result.component';
import { RegistrationComponent } from './general interface/registration/registration.component';
import { FaqComponent } from './general interface/faq/faq.component';
import { DashboardComponent } from './dashboard-components/dashboard/dashboard.component';
import { DashboardContentComponent } from './dashboard-components/dashboard-content/dashboard-content.component';
import { LotteryHistoryComponent } from './dashboard-components/lottery-history/lottery-history.component';
import { WalletComponent } from './dashboard-components/wallet/wallet.component';
import { AuthGuard } from './auth.guard';
import { UserProfileComponent } from './dashboard-components/user-profile/user-profile.component';
import { DisputeComponent } from './dashboard-components/dispute/dispute.component';
import { AboutComponent } from './general interface/about/about.component';
import {  BenefitsComponent } from './general interface/benefits/benefits.component';
import { TermsConditionComponent } from './general interface/terms-condition/terms-condition.component';
import { ChangingLivesComponent } from './general interface/changing-lives/changing-lives.component';
import { ChangePasswordComponent } from './general interface/change-password/change-password.component';
import { NoticeComponent } from './general interface/notice/notice.component';
import { JobsComponent } from './jobs/jobs.component';
import { VerifyComponent } from './verify/verify.component';
import { BuyComponent } from './general interface/buy/buy.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { AgentsComponent } from './agents/agents.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'verify/:data',
    component: VerifyComponent
  },
  {
    path: 'averify/:adata',
    component: VerifyComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'benefits',
    component: BenefitsComponent
  },
  {
    path: 'buy',
    component: BuyComponent
  },
  {
    path: 'terms-condition',
    component: TermsConditionComponent
  },
  {
    path: 'lottery-result',
    component: ResultComponent
  },
  {
    path: 'check-result',
    component: TicketsComponent
  },
  {
    path: 'registration',
    component: RegistrationComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'jobs',
    component: JobsComponent
  },

  {
    path: 'changing-life',
    component: ChangingLivesComponent
  },
  {
    path: 'notice',
    component: NoticeComponent
  },
  {
    path: 'retailers',
    component: AgentsComponent
  },
  {
    path: "change-password/:data",
    component: ChangePasswordComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: DashboardContentComponent
      },
      // {
      //   path: 'my-games',
      //   component: LotteryHistoryComponent
      // },
      {
        path: 'my-wallet',
        component: WalletComponent
      },
      {
        path: 'transactions',
        component: TransactionsComponent
      },
      {
        path: 'edit-profile',
        component: UserProfileComponent
      },
      {
        path: 'dispute',
        component: DisputeComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
