import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './general interface/home/home.component';
import { LoginComponent } from './general interface/login/login.component';
import { ResultComponent } from './general interface/result/result.component';
import { RegistrationComponent } from './general interface/registration/registration.component';
import { FaqComponent } from './general interface/faq/faq.component';
import { NavigationComponent } from './general interface/navigation/navigation.component';
import { FooterComponent } from './general interface/footer/footer.component';
import { DashboardComponent } from './dashboard-components/dashboard/dashboard.component';
import { DashboardContentComponent } from './dashboard-components/dashboard-content/dashboard-content.component';
import { LotteryHistoryComponent } from './dashboard-components/lottery-history/lottery-history.component';
import { WalletComponent } from './dashboard-components/wallet/wallet.component';
import { UserProfileComponent } from './dashboard-components/user-profile/user-profile.component';
import { GraphQLModule } from './graphql.module';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentModule } from 'ngx-moment';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { LivechatWidgetModule } from '@livechat/angular-widget'
import { CountdownModule } from 'ngx-countdown';
import { DisputeComponent } from './dashboard-components/dispute/dispute.component';
import { AboutComponent } from './general interface/about/about.component';
import { BenefitsComponent } from './general interface/benefits/benefits.component';
import { TermsConditionComponent } from './general interface/terms-condition/terms-condition.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ChangingLivesComponent } from './general interface/changing-lives/changing-lives.component';
import { ChangePasswordComponent } from './general interface/change-password/change-password.component';
import { NoticeComponent } from './general interface/notice/notice.component';
import { JobsComponent } from './jobs/jobs.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { VerifyComponent } from './verify/verify.component';
import { BuyComponent } from './general interface/buy/buy.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { AgentsComponent } from './agents/agents.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ResultComponent,
    RegistrationComponent,
    FaqComponent,
    NavigationComponent,
    FooterComponent,
    DashboardComponent,
    DashboardContentComponent,
    LotteryHistoryComponent,
    WalletComponent,
    UserProfileComponent,
    DisputeComponent,
    AboutComponent,
    BenefitsComponent,
    TermsConditionComponent,
    ChangingLivesComponent,
    ChangePasswordComponent,
    NoticeComponent,
    JobsComponent,
    VerifyComponent,
    BuyComponent,
    TicketsComponent,
    TransactionsComponent,
    AgentsComponent,

  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GraphQLModule,
    MomentModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    SweetAlert2Module.forRoot(),
    FontAwesomeModule,
    LivechatWidgetModule,
    CountdownModule,
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot()
  ],
  providers: [AuthGuard,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,

    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
