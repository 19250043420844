import { environment } from './../environments/environment';

import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

// const uri = 'http://192.168.8.100:4000/graphql'; // <-- add the URL of the GraphQL server here
// const uri = 'http://192.168.8.101:4000/graphql'; // <-- add the URL of the GraphQL server here
// const uri = 'http://192.168.43.99:4000/graphql'; // <-- add the URL of the GraphQL server here
const uri = environment.serverUrl + "graphql"; // <-- add the URL of the GraphQL server here

export function createApollo(httpLink: HttpLink) {
  return {
    link: httpLink.create({ uri }),
    cache: new InMemoryCache(),

  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
