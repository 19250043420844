import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CrudService } from 'src/app/services/crud.service';
import gql from 'graphql-tag';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  year
  subscribeForm:any;
  submitted: boolean;
  get f() {
    return this.subscribeForm.controls;
  }
  constructor(private toastr: ToastrService, private formBuilder: FormBuilder, private router: Router, private crud: CrudService) {
    this.subscribeForm = this.formBuilder.group({
      email: ["", [Validators.required]],
    });
  }


  ngOnInit() {
    this.year = new Date().getFullYear();
  }

  subscribe() {
    this.submitted = true;
    
    if (this.subscribeForm.invalid) {
      this.toastr.error("Invalid Email", "Error")
      return;
    }
      
    let query = gql`
    mutation subscribe(
      $email: String!){
        subscribe(email: $email) {
          id
        }
        }
      `;
    this.crud.postData(query, this.subscribeForm.value).subscribe(result => {

      if (!result.data['subscribe']) {
        this.toastr.error(result.errors[0].message,"Error")
        return
      }
      this.toastr.success("Your email has been successfully added to subscription list", "Success")
    })

  }
}
