import { Component, OnInit } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { ToastrService } from 'ngx-toastr';
import { ActivityService } from 'src/app/services/activity.service';
import gql from 'graphql-tag';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  banks: any;
  userProfile: any;
  bankDetails: any;
  spinner: any;
  passwordData: any;

  constructor(private crud: CrudService, private toastr: ToastrService, private http:HttpClient, private activity: ActivityService) {
    this.banks = [
      { id: "1", name: "Access Bank" },
      { id: "2", name: "Citibank"},
      { id: "3", name: "Diamond Bank"},
      { id: "4", name: "Dynamic Standar"},
      { id: "5", name: "Ecobank Nigeria"},
      { id: "6", name: "Fidelity Bank Nigeria"},
      { id: "7", name: "First Bank of Nigeria"},
      { id: "8", name: "First City Monument Bank"},
      { id: "9", name: "Guaranty Trust Bank"},
      { id: "10", name: "Heritage Bank Plc"},
      { id: "11", name: "Jaiz Bank"},
      { id: "12", name: "Keystone Bank Limited"},
      { id: "13", name: "Providus Bank Plc"},
      { id: "14", name: "Polaris Bank"},
      { id: "15", name: "Stanbic IBTC Bank Nigeria Limited"},
      { id: "16", name: "Standard Chartered Bank"},
      { id: "17", name: "Sterling Bank"},
      { id: "18", name: "Suntrust Bank Nigeria Limited"},
      { id: "19", name: "Union Bank of Nigeria"},
      { id: "20", name: "United Bank for Africa"},
      { id: "21", name: "Unity Bank Plc"},
      { id: "22", name: "Wema Bank"},
      { id: "23", name: "Zenith Bank"}
    ]

    this.bankDetails = {
      first_name: '',
      last_name: '',
      account_number: '',
      bank_name: ''
    }
  }
  passData:any = {}
  ngOnInit() {
    this.getUserObj()
    this.getUserDetails(this.userProfile.id)
    this.makeGetRequest()
  }
	makeGetRequest(): void {
		const url = 'https://api.paystack.co/bank'; // Replace with your API endpoint
		const authToken = 'sk_live_794bd88cafff660831706579c41ffa5411aec652'; // Replace with your actual authorization token
	
		const headers = new HttpHeaders({
		  Authorization: `Bearer ${authToken}`,
		});
	
		this.http.get(url, { headers }).subscribe(
		  (response) => {
			
			// Process the response data here
      this.banks = response['data']
		  },
		  (error) => {
			console.error('Error:', error);
			// Handle the error here
		  }
		);
	  }
  getUserObj() {
    this.userProfile = JSON.parse(localStorage.getItem('user'))
  }

  getBankList() {
    this.crud.pullData('all-banks', '')
      .subscribe((res: any) => {
        this.banks = res.banks
      })
  }
  submitRegistrationForm(formData) {

  }
  changePassword(data) {
    if (this.passwordData.invalid) {
      // 
      // this.submitted = false;
      return;
    }
    if (data.value.new_pass == data.value.comfirm_new_pass) {
      let passObj = {
        id: this.userProfile.id,
        password: data.value.comfirm_new_pass
      }

      let query = gql`
        mutation setPassword(
          $id: ID!
          $password: String!){
        setPassword(id: $id, password: $password) {
            id
        }
      }
    `;
      this.crud.postData(query, passObj).subscribe(result => {
        // if (!result.data['login']) {
        //   this.error = result.errors[0].message;
        // }
        let activityInput = {
          type: 'User',
          operation: 'change password',
          userId: this.userProfile.id,
          device: "web"
        }
        this.activity.record(activityInput);
        this.toastr.success("Password Changed Successful", "Success");
      },
        error => {
          this.toastr.error("Password Changed unsuccessful, Try Again", "Error");
        })
    } else {
      this.toastr.error('Your Password is not the same', 'Wrong Password')
    }
  }

  updateBank(bankData) {

    let bankObj = {
      id: this.userProfile.id,
      bank_name: bankData.value.bank_name,
      first_name: bankData.value.first_name ,
      last_name:  bankData.value.last_name,
      account_number: bankData.value.account_number
    }
    let query = gql`
    mutation editUserBankDetails($userBank: userBankType!) {
      editUserBankDetails(userBank: $userBank) {
        id
        bank_name
        first_name
        last_name
        account_number
      }
      }
    `;

    this.crud.postData(query, { userBank: bankObj }).subscribe(result => {
      // if (!result.data['createUser']) {
      //   // this.error = result.errors[0].message;
      // }
      let activityInput = {
        type: 'Bank',
        operation: 'Update bank details',
        userId: this.userProfile.id,
        device: "web"
      }
      this.activity.record(activityInput);
      this.toastr.success('Bank details updated successfully', 'Success!');
    },
      error => {
        this.toastr.error(error.errors.message, 'Error');
      })
  }

  getUserDetails(id) {
    let data = gql`
    query user($id: ID!){
      user (id: $id){
        account_number
        first_name
        last_name
        bank_name
      }
    }
  `;
    this.crud.pullData(data, { id: id })
      .subscribe(result => {
        this.bankDetails = result.data['user']
      },
        error => {
          this.toastr.error("Oops, we cannot fetch your data at this time, kindly try again", "Unsuccessful")
        });
  }
}
