import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
declare const $:any;
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private scrollTopService:ScrollService) { }

  ngOnInit() {
    this.scrollTopService.setScrollTop();
    $("#preloader").delay(300).animate({
      "opacity" : "0"
      }, 500, function() {
      $("#preloader").css("display","none");
    });
  }

}
